import $ from 'jquery'
import React, { Component } from 'react'
import PackeryModule from 'react-packery-component'
import Layout from 'components/layout'
import ImageWrap from 'components/ImageWrap'
import LinkWrap from 'components/LinkWrap'
import Inview from 'components/Inview'
import Title from 'components/title'
import {
  getImageProportions,
  productsFeaturedShuffleSort,
  productsStandardSort,
  trFunction,
} from 'utils/functions'
import { graphql } from 'gatsby'

const Packery = PackeryModule(React)

class Dropdown extends Component {
  constructor() {
    super()
    this.state = {
      isOpen: false,
    }
    this.toggle = this.toggle.bind(this)
    this.toggleOpen = this.toggleOpen.bind(this)
    this.toggleClose = this.toggleClose.bind(this)
  }

  toggle() {
    this.setState((prevState) => {
      return { isOpen: !prevState.isOpen }
    })
  }
  toggleOpen() {
    this.setState({ isOpen: true })
  }
  toggleClose() {
    this.setState({ isOpen: false })
  }

  render() {
    const title = this.props.title
    const items = this.props.items
    const addClass = this.props.addClass
    const filterType = this.props.filterType
    const setFilters = this.props.setFilters
    const activeFilterType = this.props.activeFilterType
    const activeFilterValue = this.props.activeFilterValue
    const tr = this.props.tr

    let showTitle
    if (activeFilterType === filterType) {
      let activeItem = items.filter(
        (item) => item.node.slug === activeFilterValue,
      )
      showTitle = activeItem[0].node.title
    } else if (activeFilterType === 'all' && filterType === 'productCat') {
      if (activeFilterValue === 'all') showTitle = tr('ALL_PRODUCTS')
      if (activeFilterValue === 'byCollection')
        showTitle = tr('ALL_BY_COLLECTION')
      if (activeFilterValue === 'isNew') showTitle = tr('NEW')
    } else {
      showTitle = title
    }
    const openClass = this.state.isOpen ? ' open' : ''
    return (
      <div className={`t-select ${addClass} ${openClass}`}>
        <a className="ui header" onClick={this.toggle}>
          <div
            className={
              'label ' +
              (activeFilterType === filterType ||
              (activeFilterType === 'all' && filterType === 'productCat')
                ? ' selected'
                : '')
            }
          >
            {showTitle}
          </div>
          <div className="arrow-sel" />
        </a>
        <div className="scroll">
          <ul onClick={this.toggleClose}>
            {filterType === 'productCat' && ( // for productCats, add in the "all products" filters manually
              <Filter
                filterType="all"
                filterValue="all"
                title={tr('ALL_PRODUCTS')}
                tr={tr}
                setFilters={setFilters}
                activeFilterType={activeFilterType}
                activeFilterValue={activeFilterValue}
              />
            )}
            {filterType === 'productCat' && (
              <Filter
                filterType="all"
                filterValue="byCollection"
                title={tr('ALL_BY_COLLECTION')}
                tr={tr}
                setFilters={setFilters}
                activeFilterType={activeFilterType}
                activeFilterValue={activeFilterValue}
              />
            )}
            {filterType === 'productCat' && (
              <Filter
                filterType="all"
                filterValue="isNew"
                title={tr('NEW')}
                tr={tr}
                setFilters={setFilters}
                activeFilterType={activeFilterType}
                activeFilterValue={activeFilterValue}
              />
            )}
            {items.map((item, i) => {
              return (
                <Filter
                  key={i}
                  filterType={filterType}
                  filterValue={item.node.slug}
                  title={item.node.title}
                  setFilters={setFilters}
                  activeFilterType={activeFilterType}
                  activeFilterValue={activeFilterValue}
                />
              )
            })}
          </ul>
        </div>
      </div>
    )
  }
}

const Filter = ({
  filterType,
  filterValue,
  title,
  tr,
  setFilters,
  activeFilterType,
  activeFilterValue,
}) => {
  return (
    <li
      className={
        'product-filter ' +
        (filterType === activeFilterType && filterValue === activeFilterValue
          ? 'selected'
          : '')
      }
    >
      <a
        className="ui"
        onClick={
          filterType === activeFilterType && filterValue === activeFilterValue
            ? null
            : setFilters
        }
        data-filter-type={filterType}
        data-filter-value={filterValue}
      >
        {title}
      </a>
    </li>
  )
}

const AllFilters = ({
  allProductCats,
  allDesigners,
  allCollections,
  tr,
  setFilters,
  activeFilterType,
  activeFilterValue,
}) => {
  return (
    <div id="filters">
      <div className="wrap">
        <div className="filters-inner">
          <div className="categories">
            <div className="small-caps">{tr('CATEGORIES')}</div>
            <ul className="categories-list">
              <Filter
                filterType="all"
                filterValue="all"
                title={tr('ALL_PRODUCTS')}
                tr={tr}
                setFilters={setFilters}
                activeFilterType={activeFilterType}
                activeFilterValue={activeFilterValue}
              />
              <Filter
                filterType="all"
                filterValue="byCollection"
                title={tr('ALL_BY_COLLECTION')}
                tr={tr}
                setFilters={setFilters}
                activeFilterType={activeFilterType}
                activeFilterValue={activeFilterValue}
              />
              <Filter
                filterType="all"
                filterValue="isNew"
                title={tr('NEW')}
                tr={tr}
                setFilters={setFilters}
                activeFilterType={activeFilterType}
                activeFilterValue={activeFilterValue}
              />
              {allProductCats.map((productCat, i) => {
                return (
                  <Filter
                    key={i}
                    filterType="productCat"
                    filterValue={productCat.node.slug}
                    title={productCat.node.title}
                    tr={tr}
                    setFilters={setFilters}
                    activeFilterType={activeFilterType}
                    activeFilterValue={activeFilterValue}
                  />
                )
              })}
            </ul>

            <Dropdown
              title={tr('ALL_PRODUCTS')}
              items={allProductCats}
              addClass="mobile-filterby select-categories-mobile"
              filterType="productCat"
              setFilters={setFilters}
              activeFilterType={activeFilterType}
              activeFilterValue={activeFilterValue}
              tr={tr}
            />

            {/*             
            <div className="t-select select-categories-mobile">
              <div className="header">
                <div className="label">// label here</div>
                <div className="arrow-sel"></div>
              </div>
              
              <div className="scroll">
                <ul>
                  <li className="_selected"><a className="ui">{tr('ALL_PRODUCTS')}</a></li>
                  <li className="_selected"><a className="ui">{tr('ALL_BY_COLLECTION')}</a></li>
                  // product categories here
                </ul>
              </div>
            </div> */}
          </div>
          <div className="filterby">
            <div className="small-caps">{tr('FILTER_BY')}</div>
            <Dropdown
              title={tr('DESIGNERS')}
              items={allDesigners}
              addClass="select-designers"
              filterType="designer"
              setFilters={setFilters}
              activeFilterType={activeFilterType}
              activeFilterValue={activeFilterValue}
              tr={tr}
            />
            <Dropdown
              title={tr('COLLECTIONS')}
              items={allCollections}
              addClass="select-collections"
              filterType="collection"
              setFilters={setFilters}
              activeFilterType={activeFilterType}
              activeFilterValue={activeFilterValue}
              tr={tr}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

const Product = ({ product }) => {
  const imageProportions = getImageProportions(product.imageThumb)
  let fixAspectRatio = 1 / 1.18518518518518
  if (imageProportions === 'wide') fixAspectRatio = 1 / 0.554942

  return (
    <Inview
      className={`item-grid trigger-in-view fade-up item-${imageProportions}`}
    >
      <LinkWrap to={`/collections/products/${product.slug}`}>
        <ImageWrap image={product.imageThumb} aspectRatio={fixAspectRatio} />
      </LinkWrap>
      <h4>
        <LinkWrap className="dark" to={`/collections/products/${product.slug}`}>
          {product.title}
        </LinkWrap>
      </h4>
    </Inview>
  )
}

class ProductsPage extends Component {
  constructor() {
    super()
    this.state = {
      activeFilterType: '',
      activeFilterValue: '',
      showProducts: [],
    }
    this.setFilters = this.setFilters.bind(this)
    this.updateProducts = this.updateProducts.bind(this)
  }

  updateFromStorage() {
    const getSlug = sessionStorage.getItem('productCat')
    sessionStorage.removeItem('productCat')
    this.setState(
      {
        activeFilterType: 'productCat',
        activeFilterValue: getSlug,
        showProducts: [],
      },
      this.updateProducts,
    )
  }

  UNSAFE_componentWillMount() {
    const locale = this.props.data.site.siteMetadata.locale
    const allProducts = this.props.data.allProducts.edges
    if (typeof sessionStorage !== 'undefined') {
      if (sessionStorage.getItem('productCat')) {
        this.updateFromStorage()
      } else {
        this.setState(
          {
            activeFilterType: 'all',
            activeFilterValue: 'all',
            showProducts: [],
          },
          this.updateProducts,
        )
      }
    }
  }

  componentDidUpdate() {
    if (typeof sessionStorage !== 'undefined') {
      if (sessionStorage.getItem('productCat')) {
        this.updateFromStorage()
      }
    }
  }

  setFilters(e) {
    const filterType = e.currentTarget.getAttribute('data-filter-type')
    const filterValue = e.currentTarget.getAttribute('data-filter-value')
    this.setState(
      {
        activeFilterType: filterType,
        activeFilterValue: filterValue,
        showProducts: [],
      },
      this.updateProducts,
    )
  }

  updateProducts() {
    // get the state
    const filterType = this.state.activeFilterType
    const filterValue = this.state.activeFilterValue
    const locale = this.props.data.site.siteMetadata.locale
    const allProducts = this.props.data.allProducts.edges
    let filterProducts = []
    if (filterType === 'all' && filterValue === 'all') {
      filterProducts = productsFeaturedShuffleSort(allProducts)
    }
    if (filterType === 'all' && filterValue === 'byCollection') {
      filterProducts = productsStandardSort(allProducts)
    }
    if (filterType === 'all' && filterValue === 'isNew') {
      filterProducts = allProducts.filter(
        (product) => product.node.isNew === true,
      )
    }
    if (filterType === 'productCat') {
      filterProducts = allProducts.filter((product) =>
        product.node.categories.some((cat) => cat.slug.includes(filterValue)),
      )
      filterProducts = productsStandardSort(filterProducts)
    }
    if (filterType === 'designer') {
      filterProducts = allProducts.filter(
        (product) =>
          product.node.collection.designer &&
          product.node.collection.designer.slug === filterValue,
      )
      filterProducts = productsStandardSort(filterProducts)
    }
    if (filterType === 'collection') {
      filterProducts = allProducts.filter(
        (product) => product.node.collection.slug === filterValue,
      )
      filterProducts = productsStandardSort(filterProducts)
    }

    this.setState({ showProducts: filterProducts })
  }

  render() {
    const locale = this.props.data.site.siteMetadata.locale
    const translations = this.props.data.translations
    const tr = (code) => trFunction(code, translations, locale)

    const thisPage = this.props.data.thisPage
    const allProducts = this.props.data.allProducts.edges
    const allProductCats = this.props.data.allProductCats.edges
    const allDesigners = this.props.data.allDesigners.edges
    const allCollections = this.props.data.allCollections.edges

    const packeryOptions = {
      itemSelector: '.item-grid',
      gutter: '.gutter-sizer',
      transitionDuration: '0', //'0.4s'
    }

    return (
      <Layout>
        <div id="content">
          <Title
            title={thisPage.title}
            subtitle={tr('COLLECTIONS')}
            subtitleLink="/collections"
          />
          <section className="page-content">
            <AllFilters
              allProductCats={allProductCats}
              allDesigners={allDesigners}
              allCollections={allCollections}
              tr={tr}
              setFilters={this.setFilters}
              activeFilterType={this.state.activeFilterType}
              activeFilterValue={this.state.activeFilterValue}
            />
            <div className="wrap">
              <Packery
                className={'grid-packery grid-products'}
                options={packeryOptions}
              >
                <div className="gutter-sizer" />
                {this.state.showProducts.map((product, i) => (
                  <Product key={i} product={product.node} />
                ))}
              </Packery>
            </div>
          </section>
        </div>
      </Layout>
    )
  }
}
export default ProductsPage

export const productsQuery = graphql`
  query {
    site {
      siteMetadata {
        locale
      }
    }
    translations: allContentfulStringTranslations {
      edges {
        node {
          code
          en
          zh
        }
      }
    }
    allProducts: allContentfulProducts(
      sort: { fields: [datePosted], order: DESC }
    ) {
      edges {
        node {
          categories {
            id
            slug
            order
          }
          datePosted
          isNew
          collection {
            id
            slug
            order
            designer {
              id
              slug
            }
          }
          isFeatured
          isNew
          title
          slug
          imageThumb {
            ...GatsbyImage
          }
        }
      }
    }
    allProductCats: allContentfulProductCategories(
      sort: { fields: [order], order: ASC }
    ) {
      edges {
        node {
          id
          title
          slug
        }
      }
    }
    allDesigners: allContentfulDesigners(
      sort: { fields: [order], order: ASC }
    ) {
      edges {
        node {
          id
          title
          slug
        }
      }
    }
    allCollections: allContentfulCollections(
      sort: { fields: [order], order: ASC }
    ) {
      edges {
        node {
          id
          title
          slug
        }
      }
    }
    thisPage: contentfulPages(slug: { eq: "products" }) {
      title
      slug
    }
  }
`

{
  /* <Img className={heroStyles.heroImage} alt={post.title} sizes={post.heroImage.sizes} />
heroImage {
  sizes(maxWidth: 1180, background: "rgb:000000") {
    ...GatsbyContentfulSizes_tracedSVG
  }
} */
}
